import React from 'react'
import './navbar.css'
import ShillablesLogo from '../../Assets/ShillablesLogo.svg'


const NavBar = () => {

    const Menu = ()=> {
        const handleNavClick =(id) =>{
            document.getElementById(id).scrollIntoView({block:'start'})
        }
        return(
            <>
                <section onClick={()=>{handleNavClick('about')}}>About</section>
                <section onClick={()=>{handleNavClick('roadmap')}}>Roadmap</section>
                <section onClick={()=>{handleNavClick('team')}}>Team</section>
                <section onClick={()=>{handleNavClick('projects')}}>Projects</section>
            </>
        )
    }

    return (
        <div className='NavBar'>
            <div className='NavBar-container'>
                <section onClick={()=>{window.scrollTo(0,0)}}><img src={ShillablesLogo} alt='logo'/></section>
                <div className='NavBar-menu'>
                    <Menu/>
                </div>
                <a href='https://stashh.io/collection/shillables-nft-club' target='_blank' rel="noreferrer"><div className='MarketPlace-button'>Market Place</div></a>
            </div>
        </div>
    )
}

export default NavBar