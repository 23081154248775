import React, {useRef, useEffect, useState}  from 'react'
import './story.css'

const Story = () => {
  const storyRef = useRef()
  const [storyIntersecting, setStoryIntersecting] = useState(false)

  useEffect(() => {
    const observerStory = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.4)
        setStoryIntersecting(true)
      else 
        setStoryIntersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-10% 0% 0% 0%"})
    observerStory.observe(storyRef.current)
    
    return (()=>{
      observerStory.disconnect()
    })
  }, []);

  return (
    <div className='OurStory' id='about' ref={storyRef}>
      <div className={`OurStory-content`}>
        <div className='OurStory-content-title'>our Story</div>
        <div className={`OurStory-content-description ${storyIntersecting? `storyIn`: `storyOut`}`}>
          <section>Shillables NFT Club was formed by NFT collectors for NFT collectors.  The club founder, Jax, believes in empowering communities and rewarding holders.  Jax will guide the club through Season 1, but the community will have decision-making authorities to guide all 11 Season 1 NFT project launches and holders of the S1 NFT will be rewarded with monthly airdrops of those projects. </section>
          <section>The goals are to produce monthly NFT projects through DAO governance with each project establishing its own community and roadmap.  If you are a collector and want your voice to be heard or just want to sit back, hold, and receive monthly NFTs, then join us today!</section>
        </div>

      </div>
    </div>
  )
}

export default Story