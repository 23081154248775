import React from "react";
import { useRef, useEffect, useState } from "react";
import "./team.css";
import { Team1, Team4, Team5 } from "../../Assets/TeamImages";
import { TwitterLogo } from "../../Assets/Logos";

const Team = () => {
  const team1Ref = useRef();
  const team4Ref = useRef();
  const team5Ref = useRef();
  const [team1Intersecting, setTeam1Intersecting] = useState(false);
  const [team4Intersecting, setTeam4Intersecting] = useState(false);
  const [team5Intersecting, setTeam5Intersecting] = useState(false);

  useEffect(() => {
    const observerTeam1 = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0.5) setTeam1Intersecting(true);
        else setTeam1Intersecting(false);
      },
      {
        root: null,
        threshold: [0.2, 0.3, 0.4, 0.5, 0.6],
        rootMargin: "-10% 0% 0% 0%",
      }
    );

    const observerTeam4 = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0.5) setTeam4Intersecting(true);
        else setTeam4Intersecting(false);
      },
      {
        root: null,
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6],
        rootMargin: "-10% 0% 0% 0%",
      }
    );
    const observerTeam5 = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0.5) setTeam5Intersecting(true);
        else setTeam5Intersecting(false);
      },
      {
        root: null,
        threshold: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6],
        rootMargin: "-10% 0% 0% 0%",
      }
    );

    observerTeam1.observe(team1Ref.current);
    observerTeam4.observe(team4Ref.current);
    observerTeam5.observe(team5Ref.current);

    return () => {
      observerTeam1.disconnect();
      observerTeam4.disconnect();
      observerTeam5.disconnect();
    };
  }, []);

  return (
    <div className="Team" id="team">
      <div className="Team-title">team</div>
      <section
        className={`Team-description ${
          team1Intersecting ? "teamTextIn" : "teamTextOut"
        }`}
      >
        Each and every member of Shillables NFT Club is a valuable member of our
        team, but here are some key individuals for Season 1.
      </section>
      <div className="Team-content-container">
        <section ref={team1Ref}>
          <div
            className={`Team-card ${
              team1Intersecting ? "team1In" : "team1Out"
            }`}
          >
            <img src={Team1} alt="team" />
            <div
              className={`Team-card-description ${
                team1Intersecting ? "teamDescriptionIn" : "teamDescriptionOut"
              }`}
            >
              <section>
                <span>Jax</span>
                <br />
                Founder
              </section>
              <div className="Team-card-links">
                <div>
                  <a
                    href={"https://twitter.com/nft_scrt"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section ref={team4Ref}>
          <div
            className={`Team-card ${
              team4Intersecting ? "team4In" : "team4Out"
            } `}
          >
            <img src={Team4} alt="team" />
            <div
              className={`Team-card-description ${
                team4Intersecting ? "teamDescriptionIn" : "teamDescriptionOut"
              }`}
            >
              <section>
                <span>Chiemerie</span>
                <br />
                Community Manager
              </section>
              <div className="Team-card-links">
                <div>
                  <a
                    href={"https://twitter.com/chimerie2_chi"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section ref={team5Ref}>
          <div
            className={`Team-card ${
              team5Intersecting ? "team5In" : "team5Out"
            }`}
          >
            <img src={Team5} alt="team" />
            <div
              className={`Team-card-description ${
                team5Intersecting ? "teamDescriptionIn" : "teamDescriptionOut"
              }`}
            >
              <section>
                <span>Wolfbytes</span>
                <br />
                Community Manager
              </section>
              <div className="Team-card-links">
                <div>
                  <a
                    href={"https://twitter.com/_wolfbytes"}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={TwitterLogo} alt="logo" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Team;
