import React, { useState, useRef, useEffect } from "react";
import "./projects.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NFT1 from "../../Assets/NFT2.svg";
import BananAppeals from "../../Assets/BananAppeals.svg";
import Alphacas from "../../Assets/alphacas.png";
import Fox from "../../Assets/fox.png";
import Wolf from "../../Assets/wolf.png";

const Projects = () => {
  const projectRef = useRef();
  const [projectIntersecting, setProjectIntersecting] = useState(false);

  useEffect(() => {
    const observerProject = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0.3) {
          setProjectIntersecting(true);
        } else setProjectIntersecting(false);
      },
      { root: null, threshold: [0.3, 0.7], rootMargin: "0% 0% -20% 0%" }
    );
    observerProject.observe(projectRef.current);

    return () => {
      observerProject.disconnect();
    };
  }, []);

  const sliderSettings = {
    slidesToShow: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 500,
    focusOnSelect: true,
    arrows: false,
    dots: true,
    centerMode: true,
    initialSlide: 1,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  const Project1 = () => {
    return (
      <div className="Projects-content-card1">
        <div className={`Projects-content`}>
          <li className="Projects-content-title1">SHILLABLES</li>
        </div>
        <img src={NFT1} alt="Projectsimage" />
      </div>
    );
  };

  const Project2 = () => {
    return (
      <div className="Projects-content-card2">
        <div className={`Projects-content`}>
          <li className="Projects-content-title2">BananAppeals</li>
        </div>
        <img src={BananAppeals} alt="Projectsimage" />
      </div>
    );
  };

  const Project3 = () => {
    return (
      <div className="Projects-content-card3">
        <div className={`Projects-content`}>
          <li className="Projects-content-title3">Coming Soon</li>
        </div>
        <img src={NFT1} alt="projects" />
      </div>
    );
  };

  const Project4 = () => {
    return (
      <div className="Projects-content-card4">
        <div className={`Projects-content`}>
          <li className="Projects-content-title4">Wolf Pack</li>
        </div>
        <img src={Wolf} alt="Projectsimage" />
      </div>
    );
  };

  const Project5 = () => {
    return (
      <div className="Projects-content-card5">
        <div className={`Projects-content`}>
          <li className="Projects-content-title5">Alphacas</li>
        </div>
        <img src={Alphacas} alt="Projectsimage" />
      </div>
    );
  };

  const Project6 = () => {
    return (
      <div className="Projects-content-card6">
        <div className={`Projects-content`}>
          <li className="Projects-content-title6">Sly Foxes</li>
        </div>
        <img src={Fox} alt="Projectsimage" />
      </div>
    );
  };
  return (
    <div className="Projects" id="projects" ref={projectRef}>
      <div className="Projects-title">Projects</div>
      <div
        className={`Projects-slider ${projectIntersecting ? "" : "sliderOut"}`}
      >
        <Slider
          {...sliderSettings}
          customPaging={() => {
            return <div className="custom-slide-indicator"></div>;
          }}
          dotsClass="slick-dots custom-dot-indicator"
        >
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project1 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project2 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project4 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project5 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project6 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageOddIn" : "imageOddOut"
            }`}
          >
            <Project3 />
          </div>
          <div
            className={`Projects-slider-image ${
              projectIntersecting ? "imageEvenIn" : "imageEvenOut"
            }`}
          >
            <Project3 />
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Projects;
