import React from "react";
import { useRef, useEffect, useState } from "react";
import "./footer.css";
import ShillablesLogo from "../../Assets/ShillablesLogo.svg";
import GroupImage from "../../Assets/GroupImage.png";
import { DiscordLogo, TwitterLogo, StashhLogo } from "../../Assets/Logos";

const Footer = () => {
  const footerRef = useRef();
  const [footerIntersecting, setFooterIntersecting] = useState(false);

  useEffect(() => {
    const observerFooter = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.intersectionRatio > 0.3) setFooterIntersecting(true);
        else setFooterIntersecting(false);
      },
      { root: null, threshold: [0.3] }
    );

    observerFooter.observe(footerRef.current);

    return () => {
      observerFooter.disconnect();
    };
  }, []);

  const Menu = () => {
    const handleNavClick = (id) => {
      document.getElementById(id).scrollIntoView({ block: "start" });
    };
    return (
      <div className="Footer-NavBar-menu">
        <section
          onClick={() => {
            handleNavClick("about");
          }}
        >
          About
        </section>
        <section
          onClick={() => {
            handleNavClick("roadmap");
          }}
        >
          Roadmap
        </section>
        <section
          onClick={() => {
            handleNavClick("team");
          }}
        >
          Team
        </section>
        <section
          onClick={() => {
            handleNavClick("projects");
          }}
        >
          Projects
        </section>
      </div>
    );
  };
  return (
    <div className="Footer-container" ref={footerRef}>
      <div
        className={`Footer ${footerIntersecting ? "footerIn" : "footerOut"}`}
      >
        <img
          src={ShillablesLogo}
          alt="logo"
          onClick={() => {
            window.scrollTo(0, 0);
          }}
        />
        <Menu />
        <div className="Footer-contact-links">
          <a
            href="https://discord.gg/KDtQHXh67J"
            target="_blank"
            rel="noreferrer"
          >
            <img src={DiscordLogo} alt="logo" />
          </a>
          <a
            href="https://twitter.com/shillables"
            target="_blank"
            rel="noreferrer"
          >
            <img src={TwitterLogo} alt="logo" />
          </a>
          <a
            href="https://stashh.io/collection/shillables-nft-club"
            target="_blank"
            rel="noreferrer"
          >
            <img src={StashhLogo} alt="logo" />
          </a>
        </div>
        <div className="Footer-copyright">
          © {new Date().getFullYear()} - Shillable NFT Club - All rights
          reserved.
        </div>
      </div>
      <div className="Footer-image">
        <img src={GroupImage} alt="groupImage" />
      </div>
    </div>
  );
};

export default Footer;
