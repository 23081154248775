import React, {useState} from 'react'
import './websiteview.css'
import Home from '../home/Home.jsx'
import Story from '../story/Story.jsx'
import Roadmap from '../roadmap/Roadmap.jsx'
import Team from '../team/Team.jsx'
import Projects from '../projects/Projects.jsx'
import Footer from '../footer/Footer.jsx'
import NavBar from '../navbar/NavBar'
import EllipseBlur from '../../Assets/EllipseBlur.svg'
import ShillablesLogo from '../../Assets/ShillablesLogo.svg'
import NavBarButton from '../../Assets/NavBarButton.svg'


const WebsiteView = () => {
  const [menuToggle, setMenuToggle] = useState(false)

  const menuOnClick = ()=>{
      setMenuToggle(!menuToggle)
  }

  const Menu = ()=> {

    const handleNavClick=(a)=>{
      document.getElementById(a).scrollIntoView({block:'start'})
      setMenuToggle(false)
    }

      return(
          <>
              <section  onClick={() => { handleNavClick('about') }}>About</section>
              <section  onClick={() => { handleNavClick('roadmap') }}>Roadmap</section>
              <section  onClick={() => { handleNavClick('team') }}>Team</section>
              <section  onClick={() => { handleNavClick('projects') }}>Projects</section>
          </>
      )
  }

  return (
    <div className={`WebsiteView`} id='home'>
        <NavBar/>
        <section className='Screen-BG-Blur'><img src={EllipseBlur} alt='ellipse'/></section>
        <div className='NavBar-mobile-container'>
                <img src={ShillablesLogo} alt='logo' onClick={()=>{window.scrollTo(0,0)}}/>
                <img src={NavBarButton} alt='button' onClick={()=>{menuOnClick()}}/>
        </div>
        {menuToggle ?
          <div className='NavBar-mobile-menu-container'>
            <div className='NavBar-mobile-menu'>
                <section className='NavBar-mobile-menu-image' onClick={()=>{window.scrollTo(0,0);setMenuToggle(false)}}><img src={ShillablesLogo} alt='logo'/></section>
                <Menu />
                <div className='MarketPlace-button'>Market Place</div>
            </div>
          </div>
            :
          <>
          </>
        }
        <Home/>
        <Story/>
        <Roadmap/>
        <Team/>
        <Projects/>
        <Footer/>
    </div>
  )
}

export default WebsiteView