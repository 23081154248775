import React, {useRef, useEffect, useState} from 'react'
import './home.css'
import NFTImages from '../../Assets/NFTImages.png'



const Home = () => {
  const homeTextRef = useRef()
  const [homeTextIntersecting, setHomeTextIntersecting] = useState(false)

  useEffect(() => {
    const observerHome = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.7)
        setHomeTextIntersecting(true)
      else 
        setHomeTextIntersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-10% 0% 0% 0%"})
    observerHome.observe(homeTextRef.current)
    
    return (()=>{
      observerHome.disconnect()
    })
  }, []);

  return (
    <div className='HomeScreen'>
      <div ref={homeTextRef} className='HomeScreen-content' >
        <span className={homeTextIntersecting ? 'textIn' : 'textOut'}>Season 1 Mint <br/> 100% Minted! </span>
        <img src={NFTImages} alt='nft'/>
      </div>
    </div>
  )
}

export default Home