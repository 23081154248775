import React from 'react'
import {useRef, useEffect, useState} from 'react'
import './roadmap.css'
import NFT1 from '../../Assets/NFT1.svg'
import NFT2 from '../../Assets/NFT2.svg'
import NFT3 from '../../Assets/NFT3.svg'
import NFT4 from '../../Assets/NFT4.svg'

const Roadmap = () => {
  const road1Ref = useRef()
  const road2Ref = useRef()
  const road3Ref = useRef()
  const road4Ref = useRef()
  const [road1Intersecting, setRoad1Intersecting] = useState(false)
  const [road2Intersecting, setRoad2Intersecting] = useState(false)
  const [road3Intersecting, setRoad3Intersecting] = useState(false)
  const [road4Intersecting, setRoad4Intersecting] = useState(false)
  
  useEffect(() => {

    const observerRoad1 = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.5)
        setRoad1Intersecting(true)
      else 
        setRoad1Intersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-20% 0% 0% 0%"})

    const observerRoad2 = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.5)
        setRoad2Intersecting(true)
      else 
        setRoad2Intersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-20% 0% 0% 0%"})

    const observerRoad3 = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.5)
        setRoad3Intersecting(true)
      else 
        setRoad3Intersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-20% 0% 0% 0%"})
    
    const observerRoad4 = new IntersectionObserver ( (entries)=>{
      const entry = entries[0]
      if(entry.intersectionRatio>0.5)
        setRoad4Intersecting(true)
      else 
        setRoad4Intersecting(false)

    },{root:null, threshold:[0.1,0.2,0.3,0.4,0.5,0.6,0.7], rootMargin:"-20% 0% 0% 0%"})

    observerRoad1.observe(road1Ref.current)
    observerRoad2.observe(road2Ref.current)
    observerRoad3.observe(road3Ref.current)
    observerRoad4.observe(road4Ref.current)
    
    return (()=>{
      observerRoad1.disconnect()
      observerRoad2.disconnect()
      observerRoad3.disconnect()
      observerRoad4.disconnect()
    })
  }, []);


  return (
    <div className='Roadmap' id='roadmap'>
      <div className='Roadmap-title'>ROADMAP</div>
      <div className='Roadmap-content-container'>
          <div className='Roadmap-content-card' ref={road1Ref}>
              <div 
                className={`Roadmap-content ${road1Intersecting? `road1In`: `road1Out`}`}>
                      <li className='Roadmap-content-title'>The Beginning:</li>
                      <ul>
                        <li>Complete Artwork</li>
                        <li>Launch Shillables.com</li>
                        <li>Form the club through minting on Stashh.io</li>
                      </ul>
              </div>
              <img src={NFT1} alt='roadmapimage'/>
          </div>

          <div className='Roadmap-content-card' ref={road2Ref}>
              <div 
                className={`Roadmap-content ${road2Intersecting? `road2In`: `road2Out`}`}>
                      <section className='Roadmap-content-title'>The Journey:</section>
                      <ul>
                        <li>Form the DAO</li>
                        <li>Guide 11 monthly projects from artwork to mint</li>
                        <li>Support project communities</li>
                        <li>Develop partnerships</li>
                      </ul>
              </div>
              <img src={NFT2} alt='roadmapimage'/>
          </div>

          <div className='Roadmap-content-card' ref={road3Ref}>
              <div 
                className={`Roadmap-content ${road3Intersecting? `road3In`: `road3Out`}`} >
                      <section className='Roadmap-content-title'>The Finale:</section>
                      <ul>
                        <li>S1 must eventually come to an end but the goals are to empower the community and reward holders.</li>
                        <li>So throughout the season, Jax will be working on private projects to end the season with a redacted finale.</li>
                      </ul>
              </div>
              <img src={NFT3} alt='roadmapimage'/>
          </div>

          <div className='Roadmap-content-card' ref={road4Ref}>
              <div 
                className={`Roadmap-content ${road4Intersecting? `road4In`: `road4Out`}`}>
                      <section className='Roadmap-content-title'>The Sequel:</section>
                      <ul>
                        <li>S1 holders will determine the fate of the future of the club via governance vote</li>
                        <li>Lessons learned from S1 will impact S2 as we look to grow and thrive in all market conditions</li>
                      </ul>
              </div>
              <img src={NFT4} alt='roadmapimage'/>
          </div>
      </div>
    </div>
  )
}

export default Roadmap